h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.5rem 0 1rem 0;
    //font-family: $primary-font;
    font-family: $secondary-font;
    font-weight: normal;
    line-height: 1.2;
    color: inherit;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
    //font-weight: 700;
    color: $clrLight100;
}

h2 {
    font-size: 2rem;
    //font-weight: 700;
}

h3 {
    font-size: 1.75rem;
    //font-weight: 700;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

mark {
    background: #fff8e1;
    padding: 0.25rem 0.4rem;
    border-radius: $borderRadius;
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90a4ae;
}

hr {
    border-top: solid $clrDark820;
    border-width: 1px 0 0 0;
    margin: 1.75rem 0 1.5rem;
    width: 100%;
}

p {
    margin: 0 0 0.75rem 0;
    margin: 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}

/* Font Sizes
==================================== */
.fs-x-small {
    font-size: $fs-x-small; //10
}
.fs-small {
    font-size: $fs-small; //12
}
.fs-medium {
    font-size: $fs-medium; //14
}
.fs-normal {
    font-size: $fs-normal; //16
}
.fs-m-normal {
    font-size: $fs-m-normal; //18
}
.fs-x-normal {
    font-size: $fs-x-normal; //20
}
.fs-big {
    font-size: $fs-big; //24
}
.fs-x-big {
    font-size: $fs-x-big; //30
}
.fs-super-big {
    font-size: $fs-super-big; //50
}

/* Text Colors
==================================== */
.txt-clrAccent {
    color: $clrAccent;
}

.txt-clrLight100 {
    color: $clrLight100;
}
.txt-clrLight150 {
    color: $clrLight150;
}

.txt-clrLight200 {
    color: $clrLight200 !important;
}

.txt-clrLight230 {
    color: $clrLight230;
}

.txt-clrLight240 {
    color: $clrLight240;
}
.txt-clrLight250 {
    color: $clrLight250;
}
.txt-clrLight270 {
    color: $clrLight270;
}
.txt-clrLight500 {
    color: $clrLight500;
}
.txt-clrLight600 {
    color: $clrLight600;
}
.txt-clrSucces500 {
    color: $clrSucces500;
}
.txt-clrAlert500 {
    color: $clrAlert500;
}
.txt-clrDark500 {
    color: $clrDark500;
}
.txt-clrDark800 {
    color: $clrDark800;
}
.txt-clrDark850 {
    color: $clrDark850;
}

/* Text Utilities
==================================== */
.txt-uppercase {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.txt-regular {
    font-weight: 400;
}

.txt-medium {
    font-weight: 500;
}

.txt-bold {
    font-weight: 700;
}

.txt-center {
    text-align: center !important;
    span {
        text-align: center !important;
    }
}

.txt-right {
    text-align: right !important;
    span {
        text-align: right !important;
    }
}
