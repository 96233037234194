.p-button {
    color: $clrDark800;
    background: $clrLight100;
    border: 1.5px solid $clrLight100;
    padding: 0.625rem 1.5rem;
    border-radius: 0.3125rem;
    font-size: $fs-medium;
    font-family: $primary-font;
    font-weight: 500;

    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out, opacity 0.3s ease-in-out, transform 0.2s ease-in-out;

    &:enabled:active {
        background: inherit;
        color: inherit;
        border-color: inherit;
        //transform: scale(0.95);
    }

    &:focus {
        outline: 0 none;
        outline-offset: 0;

        box-shadow: 0 0 0 0.2rem $clrDark550;
    }

    &.btn-rounded {
        border-radius: 1.25rem;
    }

    /* BTN PRIMARY
    ------------------------------ */
    &.btn-primary {
        color: $clrDark600;
        background: $clrAccent;
        border-color: $clrAccent;
        min-width: 10rem;

        &:hover {
            color: $clrDark600;
            background: $clrAccent;
            border-color: $clrAccent;
            opacity: 0.5;
        }
    }

    /* BTN SECONDARY
    ------------------------------ */
    &.btn-secondary {
        color: $clrLight200;
        background: $clrDark300;
        border-color: $clrDark300;
        min-width: 10rem;

        &:hover {
            color: $clrLight200;
            background: $clrDark300;
            border-color: $clrDark300;
            opacity: 0.5;
        }
    }

    /* BTN PRIMARY INVERT
    ------------------------------ */
    &.btn-primary-invert {
        color: $clrDark800;
        background: $clrLight100;

        border-color: $clrLight100;
        min-width: 10rem;

        &:hover {
            background-color: transparent;
            border-color: $clrLight100;
            color: $clrLight100;
        }
    }

    /* BTN OUTLINE
    ------------------------------ */
    &.btn-outline {
        background-color: transparent;
        border-color: $clrDark800;
        color: $clrDark800;
        min-width: 10rem;

        &:hover {
            color: $clrLight100;
            background: $clrDark800;
            border-color: $clrDark800;
        }
    }

    /* BTN OUTLINE INVERT
    ------------------------------ */
    &.btn-outline-invert {
        background-color: transparent;
        border-color: $clrLight100;
        color: $clrLight100;
        min-width: 10rem;

        &:hover {
            color: $clrDark800;
            background: $clrLight100;
            border-color: $clrLight100;
        }
    }

    /* BTN TEXT
    ------------------------------ */
    &.p-button-text {
        background-color: transparent;
        color: $clrAccent;
        border-color: transparent;
        text-decoration: underline;
        transition: opacity 0.25s ease-in-out, transform 0.2s ease-in-out;
        padding: 0;

        &.no-decoration {
            text-decoration: none;
        }

        &:enabled:hover {
            background: transparent;
            color: $clrAccent;
            opacity: 0.75;
        }

        &:enabled:active {
            background: inherit;
            color: inherit;
            border: none;
        }

        &:focus {
            outline: 0 none;
            outline-offset: 0;

            box-shadow: none;
        }
    }

    /* BTN ICON ONLY
    ------------------------------ */
    &.p-button-icon-only {
        background-color: transparent;
        color: inherit;
        border: none;
        transition: opacity 0.25s ease-in-out;

        &:hover {
            background-color: transparent;
            border: none;
            color: inherit;
            opacity: 0.5;
        }

        &.btn-secondary {
            color: $clrLight200;
            background: $clrDark300;
            border-color: $clrDark300;

            &:hover {
                color: $clrLight200;
                background: $clrDark300;
                border-color: $clrDark300;
                opacity: 0.5;
            }
        }

        &.btn-alert {
            color: $clrAlert500;
        }

        &.p-button-outlined {
            border: 1px solid $clrLight100;
            border-color: $clrLight100;
            color: $clrLight100;
        }
        &.p-button-outlined-invert {
            border: 1px solid $clrDark800;
            border-color: $clrDark800;
            color: $clrDark800;
        }

        &.p-button-rounded {
            &.p-rounded-small {
                height: 1.625rem;
                width: 1.625rem;
                .pi {
                    font-size: 0.625rem;
                }
            }
        }
    }

    /* BTN BACK
    ------------------------------ */
    &.btn-back {
        text-decoration: none;
        font-size: $fs-normal;
        border: none;

        color: $clrLight170;

        .pi {
            font-size: $fs-small;
        }
    }
}

.timer {
    display: block;
    width: 6rem;
    text-align: center;
}

.p-link {
    font-family: $primary-font;

    &:focus {
        box-shadow: none;
    }
}

.p-button.p-button-outlined:enabled:hover {
    background-color: inherit;
    color: inherit;
}
