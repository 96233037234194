//Forms Groups
.p-field > label {
    font-size: $fs-medium;
}

//Input Text
.p-inputtext {
    background-color: $clrDark400;
    border: 1px solid $clrDark400;
    border-radius: 5px;

    font-family: $primary-font;
    font-size: $fs-medium;

    padding: 0.625rem 0.75rem;

    color: $clrLight200;

    &.rounded {
        border-radius: 1.25rem;
        padding: 0.625rem 1.5rem;
    }

    &:enabled:hover {
        border-color: $clrDark800;
    }

    &:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $clrDark550;
        border-color: $clrDark800;
    }

    &[type="search"] {
        min-width: 245px;

        &::-webkit-search-cancel-button {
            display: none;
        }
    }

    &::placeholder {
        color: $clrLight230;
    }
}

.p-input-icon-right > i:last-of-type {
    right: 1rem;
    color: $clrLight100;
}

//Dropdown
.p-dropdown {
    background-color: $clrDark400;
    border: 1px solid $clrDark400;
    &:not(.p-disabled):hover {
        border-color: $clrDark800;
    }
    &:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $clrDark550;
        border-color: $clrDark800;
    }
}

//CheckBox
.p-checkbox {
    .p-checkbox-box {
        border: 2px solid $clrDark500;
        background: #ffffff;
        width: 20px;
        height: 20px;
        color: #495057;
        border-radius: 5px;

        &.p-highlight {
            border-color: $clrAccent;
            background: $clrAccent;
        }

        .p-checkbox-icon {
            color: $clrDark400;
            font-size: $fs-x-small;
            font-weight: 700;
        }

        .p-focus {
            box-shadow: none;
        }
    }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;

    box-shadow: none;
    border-color: transparent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $clrAccent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $clrAccent;
    background: $clrAccent;
    color: $clrLight100;
}

//Datapicker Calendar
.p-calendar .p-datepicker {
    min-width: 93%;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    font-size: $fs-medium;
}

.p-datepicker table th > span {
    font-size: $fs-medium;
    width: 1.5rem;
    height: 1.5rem;
}

.p-datepicker table td {
    padding: 0;
}

.p-datepicker table td > span {
    font-size: $fs-small;
    width: 1.5rem;
    height: 1.5rem;
}

//Iconos en Inputs
.p-inputgroup-addon:last-child {
    background-color: $clrDark400;
    border: none;
}
