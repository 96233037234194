.layout-main {
    transition: margin-left $transitionDuration;
    padding: 0 1rem;
}

.inner-container {
    padding: 0;
}

/*  Login Page - LoginScreen.js
======================================== */
.login-page {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    // margin: 0 -1rem;

    .form-login {
        width: 60%;

        .logo {
            width: 50px;
        }

        input,
        .login-btn {
            width: 100%;
        }

        .forgot-btn {
            font-size: $fs-small;
            color: $clrLight200;
            text-decoration: underline;
            padding: 0;
        }
    }
    .bg-login {
        padding: 0;
        max-height: 100vh;
        overflow: hidden;
        .bg-login-img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}

/*  Header Detalle del Club
======================================== */
.header-club-detail {
    background-color: $clrDark400;
    border-radius: 0;
    margin-right: -1rem;
    margin-left: -1rem;

    padding: 2.25rem 2rem;

    .header-club-img {
        width: 70px;
        height: 70px;
        border-radius: 50%;

        border: 2.5px solid $clrLight200;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .header-club-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        color: $clrLight170;

        .list-item {
            margin-right: 2rem;
            font-size: $fs-medium;
            display: flex;
            align-items: center;

            i {
                margin-right: 0.5rem;
            }
        }
    }
}

.licences-container {
    background: $clrDark400;
    border-radius: 1rem;
    padding: 2rem;

    .licences-box {
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.5rem 1rem;
        position: relative;

        &:nth-child(2) {
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: $clrLight110;
                top: 0;
            }

            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
        p {
            margin: 0;
        }
    }
}

/*  Header Events.js
======================================== */
.header-events {
    background-color: $clrDark400;
    border-radius: 0;
    margin-right: -1rem;
    margin-left: -1rem;

    padding: 2.25rem 2rem 5rem;

    color: $clrLight200;
}

.container-tabs-events {
    margin-top: -54px;
}

/* Tabs Panels */
.p-tabview {
    .p-tabview-nav {
        background-color: transparent;
        border: none;
        li {
            .p-tabview-nav-link {
                background: $clrDark400;
                color: $clrLight400;
                font-weight: 400;
                border-radius: 0;
                border-bottom: 4.5px solid transparent;

                &:not(.p-disabled):focus {
                    box-shadow: none;
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    background: $clrDark400;
                    color: $clrLight200;
                    font-weight: 700;
                    border-bottom: 4.5px solid $clrAccent;
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
                background: $clrDark400;
                color: $clrLight200;
                font-weight: 700;
                border-bottom: 4.5px solid #d6ff22;
            }
        }
    }
}

.p-tabview .p-tabview-panels {
    background: transparent;
}

/* Live Now Panel */
.livenow-panel {
    background-color: $clrDark400;
    border-radius: 0.5rem;
    color: $clrLight200;
    padding: 0.75rem;

    p {
        margin: 0;
        flex: 1;
    }

    .tag-running {
        background: $clrLight100;
        padding: 0.15rem 0.75rem;
        color: $clrDark800;
        font-size: $fs-x-small;
        border-radius: 3px;
        margin: 0 0.25rem;
        text-transform: uppercase;

        &:first-of-type {
            margin-left: 0.5rem;
        }
    }

    .pi {
        font-size: 0.45rem;
    }
}

/* Tag Finished Event */
.tag-finished-event {
    background-color: $clrDark800;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    color: $clrLight100;
    font-size: $fs-x-small;
    text-transform: uppercase;
    vertical-align: middle;
    margin-left: 0.875rem;
}

/*  Mapa Container Detalle Evento Event.js
======================================== */

.header-drivers-list {
    background-color: $clrDark800;
    padding: 1rem 1.5rem;
    color: $clrLight100;
    margin-right: -1rem;
    margin-left: -1rem;
}

/*  Mapa Container Detalle Evento Event.js
======================================== */
.map-main-container {
    background-color: $clrDark400;
    border-radius: 1rem;
    overflow: hidden;
    padding-bottom: 2rem;
    // padding-top: 2rem;

    .map {
        max-height: 50vh;
        overflow: hidden;
        padding: 0;
        margin-bottom: 3.5rem;
    }

    .map-box-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 1rem 0 0.75rem;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            width: 1px;
            height: 100%;
            background-color: $clrLight110;
            top: 0;
        }
    }

    .map-box {
        max-width: 46%;

        .map-box--header {
            margin-bottom: 1.5rem;

            h3 {
                margin: 0;
            }
        }
    }

    .map-list {
        padding: 0;
        margin: 0;
        list-style: none;
        color: $clrLight250;

        .map-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            &.border-bottom {
                border-bottom: 1px solid $clrDark810;

                padding-bottom: 1rem;

                &:last-child {
                    border: none;
                }
            }
            p {
                margin: 0;
                flex: 1;

                &.latlong {
                    flex: 1;
                }
            }

            i {
                font-size: 0.85rem;
                margin-right: 0.5rem;
                //color: $clrDark850;
            }

            .map-number {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                margin-right: 0.25rem;
                color: $clrLight200;
                background-color: $clrDark300;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $fs-small;

                &:last-of-type {
                    margin-right: 0.75rem;
                }
            }

            .alert-tag {
                padding: 0.25rem;
                color: $clrLight100;
                font-size: $fs-x-small;
                border-radius: 3px;
                text-transform: uppercase;
                min-width: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.875rem;

                &.tag-ok {
                    background-color: $clrSucces500;
                }
                &.tag-sos {
                    background-color: $clrAlert500;
                }
            }
        }
    }
}

/* Edit Map - Menu Edicion
======================================== */

.menu-edit-map {
    background-color: $clrDark800;
    padding: 1rem 1.5rem;
    color: $clrLight100;
    display: flex;
    align-items: center;
    margin-right: -1rem;
    margin-left: -1rem;

    p {
        margin: 0;
    }
}

/* #drawing-manager-example {
    border: 1px solid red;
} */

.info-window-body {
    padding: 0.25rem;

    font-family: $primary-font;
    min-width: 20rem;

    color: $clrDark800;

    .info-window-edit-container {
        margin-left: 0.5rem;
    }

    .info-window-edit-btn {
        width: 1.5rem;
        padding: 0;

        .pi {
            font-size: 0.75rem;
        }
    }

    .ss-tag {
        background-color: $clrDark800;
        color: $clrLight100;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: $fs-x-small;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.1rem;

        &:first-child {
            margin-left: 0.25rem;
        }
    }
}
