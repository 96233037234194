.splash-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    //@include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
    @include linear-gradient-left($clrDark800, $clrDark400);

    .splash-container {
        width: 40px;
        height: 40px;
        margin: 0px auto;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -20px;
        margin-top: -20px;
    }

    .splash-double-bounce1,
    .splash-double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $clrAccent;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        animation: splash-bounce 2s infinite ease-in-out;
    }

    .splash-double-bounce2 {
        animation-delay: -1s;
    }

    @-webkit-keyframes splash-bounce {
        0%,
        100% {
            -webkit-transform: scale(0);
        }
        50% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes splash-bounce {
        0%,
        100% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
    }
}
