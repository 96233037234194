@font-face {
    font-family: "sharp_groteskmedium_20";
    /* src: url("../../public/fonts/sharpgrotesk-medium20-webfont.woff2") format("woff2"), url("../../public/fonts/sharpgrotesk-medium20-webfont.woff") format("woff"); */

    src: local("sharpgrotesk-medium20-webfont.woff"), url("../fonts/sharpgrotesk-medium20-webfont.woff") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@import "./_variables";
@import "./sass/_layout";
@import "./_overrides";
