* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: $primary-font;
    font-size: $fontSize;
    color: $clrLight200;
    background-color: $clrDark600;
    // background-color: #ccc;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: $clrLight100;
    font-family: $primary-font;
}

.layout-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.p-component {
    font-family: $primary-font;
}

.loading-container {
    background-color: $clrDark800;

    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    top: 0;
    width: calc(100% - 250px);
    z-index: 100;
    left: 250px;
    right: 0;

    h3 {
        font-size: $fs-big;
        color: $clrLight200;
    }
}
