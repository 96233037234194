.personal-detail-card {
    background-color: $clrDark400;
    max-width: 33%;
    border-radius: 1rem;
    padding: 2rem 1.75rem;

    .img-profile {
        overflow: hidden;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 2px solid #d6d9db;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #181f26;
        background-color: #353739;
        margin-bottom: 1rem;
        font-weight: 700;
        font-size: 2.5rem;
        cursor: pointer;
        margin-bottom: 2.75rem;
    }

    .p-field {
        p {
            margin-bottom: 0.5rem;
        }
    }
}
