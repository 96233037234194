/* General */
$fontSize: 16px;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;
$focusShadowColor: #8dcdff;

/* Menu Common */
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;
$menuitemActiveRouteColor: #1fa1fc;

/* Menu Light */
$menuBgColorFirst: #f3f4f9;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #232428;
$menuitemHoverColor: #0388e5;
$menuitemActiveColor: #0388e5;
$menuitemActiveBgColor: #ffffff;
$menuitemBorderColor: rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst: #4d505b;
$menuDarkBgColorLast: #3b3e47;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: #0388e5;
$menuitemDarkActiveColor: #0388e5;
$menuitemDarkActiveBgColor: #2e3035;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor: #0388e5;
$topbarRightBgColor: #07bdf4;
$topbarItemBadgeBgColor: #ef6262;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: #c3e8fb;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #ffffff;

/* ============================================================ 
                        NUEVAS VARIABLES
============================================================= */

/*Paleta de colores 
==============================================================*/

//Light
$clrLight100: #ffffff;
$clrLight110: rgba(255, 255, 255, 0.1);
$clrLight140: rgba(255, 255, 255, 0.4);
$clrLight150: rgba(255, 255, 255, 0.5);
$clrLight170: rgba(255, 255, 255, 0.7);
$clrLight300: #f7f7f7;
$clrLight500: #ebebeb;
$clrLight550: #dddddd;
$clrLight600: #c0c0c0;

//Success
$clrSucces500: #4ac795;

//Alert
$clrAlert500: #e65964;

//Dark
$clrDark500: #707070;
$clrDark550: rgba(112, 112, 112, 0.5);
$clrDark800: #454545;
$clrDark810: rgba(69, 69, 69, 0.1);
$clrDark820: rgba(69, 69, 69, 0.2);
$clrDark850: rgba(69, 69, 69, 0.5);
$clrDark880: rgba(69, 69, 69, 0.8);

//Nueva Paleta 10-06
$clrAccent: #d6ff22;

$clrLight200: #d6d9db;
$clrLight230: rgba(214, 217, 219, 0.3);
$clrLight240: rgba(214, 217, 219, 0.4);
$clrLight250: rgba(214, 217, 219, 0.5);
$clrLight270: rgba(214, 217, 219, 0.7);
$clrLight300: #b5babe;
$clrLight400: #9c9c9e;

$clrDark300: #33414c;
$clrDark400: #181f26;
$clrDark600: #12151a;
$clrDark800: #0c0e10;

/*Font Sizes 
==============================================================*/
$primary-font: "DM Sans", sans-serif;
$secondary-font: "sharp_groteskmedium_20", sans-serif;

/*Font Sizes 
==============================================================*/

$fs-x-small: 0.625rem; //10px
$fs-small: 0.75rem; //12px
$fs-medium: 0.875rem; //14px
$fs-normal: 1rem; //16px
$fs-m-normal: 1.125rem; //18px
$fs-x-normal: 1.25rem; //20px
$fs-big: 1.5rem; //24px
$fs-x-big: 1.875rem; //30px
$fs-super-big: 3.125rem; //50px
