/*Cards
==============================================================*/
.card {
    //background-color: $clrLight100;

    padding: 1em;
    margin-bottom: 16px;
    //border: 1px solid rebeccapurple;

    &.card-w-title {
        padding-bottom: 2em;
    }
}

/*Modals Dialog
==============================================================*/

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

.p-dialog {
    border-radius: 1rem;
    min-width: 620px;
    width: 620px;
    padding: 0 0.5rem;
    background: $clrDark600;
    font-family: $primary-font;
    .p-dialog-header {
        border-bottom: 0 none;
        background: $clrDark600;
        color: $clrLight200;
        padding: 2rem 2rem;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;

        .p-dialog-title {
            font-weight: 700;
            font-size: $fs-big;
        }
    }

    .p-dialog-content {
        background: $clrDark600;
        color: $clrLight200;
        padding: 0 2rem 1rem;

        &.overflow-y-visible {
            overflow-y: visible;
        }

        /* width */
        &::-webkit-scrollbar {
            width: 0.25rem;
            padding: 2rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $clrDark600;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $clrLight300;
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $clrLight300;
        }
    }

    .p-dialog-footer {
        border-top: 0 none;
        background: $clrDark600;
        color: $clrLight200;
        padding: 2rem 2rem 2.5rem;
        text-align: center;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
}

.p-dialog-small {
    min-width: 460px;
    width: 420px;

    .p-dialog-header {
        justify-content: center;
        .p-dialog-title {
            font-size: $fs-x-normal;
        }

        .p-dialog-header-icons {
            display: none;
        }
    }

    .p-dialog-content {
        text-align: center;
        padding: 0 2rem 1rem;
    }
}

.p-dialog-large {
    min-width: 770px;
    width: 770px;
}

.step-dialog {
    position: absolute;
    top: 38px;
    right: 35%;
}

/*Backgrounds Colors
==============================================================*/

.bg-clrLight300 {
    background: $clrLight300;
}

.bg-clrDark800 {
    background: $clrDark800;
}
