.p-datatable {
    font-family: $primary-font;

    &.table-layout-auto {
        table {
            table-layout: auto;
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 0 14px;

        tr td:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        tr td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .p-datatable-wrapper {
        overflow-x: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $clrLight600;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $clrDark800;
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $clrDark880;
        }
    }

    .p-datatable-header {
        background: transparent;
        color: $clrLight200;
        border: none;
        padding: 1rem 0rem;
        font-weight: 700;
    }

    .p-datatable-thead > tr > th {
        text-align: left;
        padding: 1rem 0.5rem;
        font-weight: 500;
        color: $clrLight140;
        background: transparent;
        width: 1px;
        white-space: nowrap;
        border: none;
        //border: 1px solid green;

        .p-column-title {
            font-size: $fs-x-small;
            text-transform: uppercase;
            //border: 1px solid red;
        }

        &:first-child {
            padding-left: 2rem;
        }
    }

    .p-datatable-tbody {
    }

    .p-datatable-tbody > tr {
        color: $clrLight170;
        background-color: $clrDark400;
        transition: color 0.2s ease-in-out;

        td {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            transition: border-color 0.2s ease-in-out;

            &:first-child {
                border-left: 1px solid transparent;
                padding-left: 2rem;
            }
            &.is-last {
                border-right: 1px solid transparent;
            }
        }

        &:hover {
            color: $clrLight100;

            td {
                border-top: 1px solid $clrAccent;
                border-bottom: 1px solid $clrAccent;

                &:first-child {
                    border-left: 1px solid $clrAccent;
                }

                &.is-last {
                    border-right: 1px solid $clrAccent;
                }
            }
        }
    }

    /*  .p-datatable-tbody > tr:nth-child(even) {
        background-color: $clrDark400;
    }

    .p-datatable-tbody > tr:nth-child(odd) {
        background-color: $clrDark400;
    } */

    .p-datatable-tbody > tr.p-highlight {
        color: $clrLight100;
        background-color: $clrDark400;

        td {
            border-top: 1px solid $clrAccent;
            border-bottom: 1px solid $clrAccent;

            &:first-child {
                border-left: 1px solid $clrAccent;
            }

            &.is-last {
                border-right: 1px solid $clrAccent;
            }
        }
    }

    .p-datatable-tbody > tr > td {
        padding: 0.85rem 0.5rem 0.85rem;
        //border: none;
        //border: 1px solid green;
        width: 1px;
        white-space: nowrap;

        .p-column-title {
            font-size: $fs-medium;
            display: block;
            //border: 1px solid red;
            /* min-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
        }
    }

    .p-sortable-column:not(.p-highlight):hover {
        background: transparent;
        color: $clrLight100;

        .p-sortable-column-icon {
            color: $clrLight100;
        }
    }

    .p-sortable-column.p-highlight {
        background: transparent;
        color: $clrLight100;

        &:hover {
            background: transparent;
            color: $clrLight100;

            .p-sortable-column-icon {
                color: $clrLight100;
            }
        }

        .p-sortable-column-icon {
            color: $clrLight100;
        }
    }

    tr > th.is-first,
    tr > th.is-last,
    tr > td.is-first,
    tr > td.is-last {
        width: 10px !important;
        // border: 1px solid green !important;
        text-align: center;
    }

    tr > th,
    tr > td {
        //border: 1px solid red !important;
    }
}

/* Paginator */

.p-paginator {
    border: none;
    margin-top: 3.75rem;
    background-color: transparent;
    .p-paginator-pages {
        .p-paginator-page {
            min-width: 1.75rem;
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
            font-size: $fs-medium;

            &.p-highlight {
                background-color: $clrAccent;
                color: $clrDark600;
            }
        }
    }
}

/* tr > td:first-child,
tr > td:last-child {
    width: 5px !important;
    border: 1px solid red;
} */
