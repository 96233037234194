@font-face {
    font-family: "icomoon";
    src: url("../../fonts/icomoon.eot?qilf9o");

    src: url("../../fonts/icomoon.eot?qilf9o#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?qilf9o") format("truetype"), url("../../fonts/icomoon.woff?qilf9o") format("woff"), url("../../fonts/icomoon.svg?qilf9o#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arroba:before {
    content: "\e900";
}
.icon-assistance:before {
    content: "\e901";
}
.icon-calendar:before {
    content: "\e902";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-finish:before {
    content: "\e904";
}
.icon-gas-station:before {
    content: "\e905";
}
.icon-hospital:before {
    content: "\e906";
}
.icon-hotel:before {
    content: "\e907";
}
.icon-instagram:before {
    content: "\e908";
}
.icon-licensing:before {
    content: "\e909";
}
.icon-location-on:before {
    content: "\e90a";
}
.icon-service-park:before {
    content: "\e90b";
}
.icon-start:before {
    content: "\e90c";
    color: #18aa56;
}
.icon-time:before {
    content: "\e90d";
}
.icon-users:before {
    content: "\e90e";
}
